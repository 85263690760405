/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect } from 'react';
import {  Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleXmark, faMagnifyingGlass, faSort, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import { getUserListRequest } from '../../../redux/modules/settings/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import { toaster } from '../../../utils/toast';
import { toast } from 'react-toastify';
import XDeleteModal from '../../../component/modal/XDeleteModal';
import XButton from '../../../component/button/XButton';
import { useNavigate } from 'react-router-dom';
import { debounce } from '../../../utils/helper';
import useSidebar from '../../../hooks/useSidebar';
import PaginationNav from '../../../component/pagination/pagination';
import XLoader from '../../../component/loader/XLoader';
import { ROLE } from '../../../utils/enums';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}
const Users = () => {
  const dispatch = useDispatch();
  const {userlist,loading} = useSelector((state: RootState) => state.settings);
  const { deleteData } = useAxios();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    limit: 10,
  });
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [totalPages, setTotalPages] = useState<number>(0);
  const { onShowSidebar } = useSidebar();
  useEffect(() => {
    dispatch(getUserListRequest(queryParams));
  }, [queryParams])
  useEffect(() => {
    const totalContacts = userlist?.totalCount;
    const totalPages = Math.ceil(totalContacts / queryParams?.limit);
    setTotalPages(totalPages);
  }, [userlist?.totalCount])

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };
  const handleSearchChange = (searchValue: string): void => {
    setQueryParams((prevParams) => ({ ...prevParams, search: searchValue }));

  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 500);


const handleDeleteUser =async(id:string)=>{
  try {
  const response=await deleteData(`${API_ENDPOINTS.DELETE_USER}/${id}`);
  if (response && response.success === true) {
    toast(response.message);
    dispatch(getUserListRequest(queryParams));
  }
  } catch (error) {
    toaster({ success: false, message: "error fetching users" });
  }

}
const handleDeleteIconClick = (user:any) => {
  setSelectedUser(user);
  setShowDeleteModal(true);
};
  const addUserButtonHandler = ()=>{
    navigate('addUser')
  }
const handleEditClick=(id:any)=>{
  navigate(`addUser/${id}`)

}
const handleSortClick = (column: string) => {
  if (sortColumn === column) {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    setSortColumn(column);
    setSortOrder('asc');
  }
};
const sortedUsers = [...(userlist?.users || [])].sort((a, b) => {
  if (sortColumn) {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  }
  return 0;
});

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between main-title-block">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Users</h2>
        <div className="filter-bar d-flex align-items-center row-item top-block">
          <div className='position-relative search-bar'>
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              // value={queryParams.search}
              onChange={(e) => debouncedHandleSearchChange(e.target.value)}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        <XButton buttonText='Add New User' className="btn-theme" onClick={addUserButtonHandler}/>
        </div>
        {/* <Button
            variant="primary"
            type="submit"
            className="btn-theme"
          >Add User
          </Button> */}
      </div>

      <div className="table-listing all-contacts users-list position-relative">
        <Table responsive>
          <thead>
            <tr>
              <th >First Name <FontAwesomeIcon icon={faSort} onClick={() => handleSortClick('first_name')}/></th>
              <th >Last Name <FontAwesomeIcon icon={faSort} onClick={() => handleSortClick('last_name')}/></th>
              <th>Email Address</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions </th>
            </tr>
          </thead>
          
          <tbody>
          {!loading && sortedUsers?.length === 0 &&
            <tr>
              <td colSpan={9}>
            <p className='text-center no-records'>No Users found.</p>
            </td>
            </tr>}

            {!loading && sortedUsers && sortedUsers?.map((user:any) => (
               <tr key={user.id}>
               <td>{user.first_name}</td>
               <td>{user.last_name}</td>
               <td>{user.email}</td>
               <td>{Object.keys(ROLE).find((key) => ROLE[key as keyof typeof ROLE] === user.role_id)}</td>
               {/* <td>
                 <Form.Group className="form-group mb-0" controlId="exampleForm.ControlInput1">
                   <Form.Select aria-label="Default select example"
                    value={selectedRoles[user.id] || user.role_id || ''}
                    onChange={(e) => {
                                        setSelectedRoles((prevSelectedRoles) => ({
                                          ...prevSelectedRoles,
                                          [user.id]: e.target.value,
                                        }));
                                      }}
                                      disabled={user.is_active}

                   >
                   <option value="">Select Role</option>
                    {roles.map((role: any) => (
                      <option key={role.id} value={role.id}>
                        {role.roleName}
                      </option>
                    ))}
                   </Form.Select>
                 </Form.Group>
               </td> */}
               <td>
                  {user?.is_active ? (
  <span className='text-green'>Activate</span>
  ) : (
    <span className='text-red'>Deactivate</span>
  )}
                 {/* <Modal show={show} onHide={handleClose} centered>
                   <Modal.Header closeButton>
                   </Modal.Header>
                   <Modal.Body>
                     <span className="content">
                       <p>Are you sure you want to activate this account?</p>
                     </span>
                   </Modal.Body>
                   <Modal.Footer className='justify-content-center'>
                     <Button variant="primary" type="submit" className='btn-theme btn-green' onClick={handleClose}>
                       Yes
                     </Button>
                     <Button variant="primary" className='btn-theme btn-red' >
                       No
                     </Button>
                   </Modal.Footer>
                 </Modal> */}
               </td>
               <td className='actions'>
                 <FontAwesomeIcon icon={faCircleXmark}  
                 onClick={() => handleDeleteIconClick(user)}
                 />
                 <FontAwesomeIcon icon={faPenToSquare} onClick={()=>handleEditClick(user?.id)} />
               </td>
             </tr> 
              ))}       
          </tbody>
        </Table>
        {loading && <XLoader />}
      </div>
          <XDeleteModal
            show={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            title="Delete User"
            body={`Are you sure you want to delete the user ${selectedUser?.first_name} ${selectedUser?.last_name}?`}
            onConfirm={() => {
              if (selectedUser) {
                handleDeleteUser(selectedUser.id);
              }    setShowDeleteModal(false);
            }}
          onCancel={() => setShowDeleteModal(false)} 
    />
      {!loading && sortedUsers?.length !== 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default Users
