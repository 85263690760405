/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect ,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Form ,Button ,Table ,Modal} from 'react-bootstrap';
import { ActionMeta} from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPenToSquare, faCircleXmark, faSort, faPlay, faSortUp, faSortDown, faFileArrowDown, faCheckDouble, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../redux/rootReducer';
import { addListsRequest, deleteListsRequest, getListsRequest } from '../../../redux/modules/lists/listsSlice';
import { API_ENDPOINTS } from '../../../constants/api';
import { addListParams ,Option } from '../../../redux/modules/lists/types';
import { toast } from 'react-toastify';
import { tableHeadings_List } from '../../../constants/constants';
import XDeleteModal from '../../../component/modal/XDeleteModal';
import useSidebar from '../../../hooks/useSidebar';
import { getPredefinedFieldRequest } from '../../../redux/modules/settings/settingsSlice';
import XLoader from '../../../component/loader/XLoader';
import XButton from '../../../component/button/XButton';
import PaginationNav from '../../../component/pagination/pagination';
import { toaster } from '../../../utils/toast';
import { debounceAsync, downloadCsv } from "../../../utils/helper";
import { columnTypes, PermissionObject } from '../../../redux/modules/auth/types';
import AsyncSelect from "react-select/async";
import useAxios from '../../../hooks/useAxios';
import { Col, Row} from 'react-bootstrap';

interface ListProps {
  permission?: PermissionObject
}

const Lists: React.FC<ListProps> = (props) => {
  const { permission } = props;
  const dispatch = useDispatch();
  const { list ,successRes ,listRes ,errorRes, loading} = useSelector((state: RootState) => state.lists);
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [queryParams, setQueryParams] = useState({search: '',page: 1,limit: 10,});
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [listId, setListId] = useState<string>('');
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedList, setSelectedList] = useState<any| null>(null);
  const { onShowSidebar } = useSidebar();
  const { predefinedFieldList } = useSelector((state: RootState) => state.settings);
  const { postData, fetchData } = useAxios();
  const [existingFields, setExistingFields] = useState<string[]>([]);
  const [preselectedValue, setPreselectedValue] = useState<{ csvField: string; databaseField: string }[]>([]);
  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [csvFields, setCsvFields] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getListsRequest(queryParams));
    dispatch(getPredefinedFieldRequest());
  }, [queryParams])

  useEffect(() => {
    const totalCount = list?.totalCount;
    const totalPages = Math.ceil(totalCount / queryParams?.limit);
    setTotalPages(totalPages);
  }, [list?.totalCount])


  const validationSchema = Yup.object({
    listName: Yup.string().trim().required('List name is required').test(
      (value) => value.trim() !== ''
    ),
  });

  const formik = useFormik<addListParams>({
    initialValues: {
      listName: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values: addListParams) => {   
      const payload={
        ...values,
        selectedOptions,
        update_id:listId
      }
      if (!csvFile && selectedOptions.length === 0) {
        toaster({success: false, message: 'Please select at least one option from contacts or upload CSV file'});
      } else {
        if (csvFile) {
          const formData = new FormData();
          formData.append('listData', JSON.stringify(payload));
          formData.append('csvFile', csvFile);
          formData.append('mappedData', JSON.stringify(preselectedValue));
          dispatch(addListsRequest(formData));
          setCsvFile(null)
        } else {
          dispatch(addListsRequest(payload));
        }    
      }
    },
  });

  const customFieldValidationSchema = Yup.object({
    customFields: Yup.array().of(
      Yup.object().shape({
        columnName: Yup.string().trim()
          .required('Column Name is required')
          .matches(/^[A-Za-z_0-9 ]*$/, 'Column Name can only contain letters, space and underscores')
          .matches(/^[^0-9]*$/, 'Column Name cannot contain numbers'),
        columnType: Yup.string().required('Column Type is required'),
      })
    ),
  });

  const customFieldsFormik = useFormik({
    initialValues: {
      customFields: [{ columnName: '', columnType: '' }]
    },
    validationSchema: customFieldValidationSchema,
    onSubmit: async (values) => {
      const { message, success } = await postData(API_ENDPOINTS.ADD_CUSTOM_FIELD, values);
      toaster({ message, success });
      if (success) {
        setShowCustomFieldsModal(false);
        await getPredefinedAndCustomFieldsName()
        customFieldsFormik.resetForm();
      }
    },
  });

  useEffect(() => {
    const normalizedExistingFields = existingFields.map((field:any) =>
      field.toLowerCase().replace(/_/g, "").replace(/\s+/g, "")
    );

    const normalizedCsvFields = csvFields.map((field:any) =>
      field.toLowerCase().replace(/_/g, "").replace(/\s+/g, "")
    );

    const matchedFields = csvFields.map((csvField:any, index:any) => {
      const normalizedCsvField = normalizedCsvFields[index];
      const matchIndex = normalizedExistingFields.findIndex(
        (existingField:any) => existingField === normalizedCsvField
      );

      if (matchIndex !== -1) {
        return {
          databaseField: existingFields[matchIndex],
          csvField,
        };
      } else {
        return {
          databaseField: "",
          csvField,
        };
      }
    });

    setPreselectedValue(matchedFields);
  }, [existingFields, csvFields]);

  const getPredefinedAndCustomFieldsName = async () => {
    const response = await fetchData(API_ENDPOINTS.GET_PREDEFINED_FILEDS);
    if (response.success) {
      const predefinedFields = response.data.predefinedFields || [];
      const extractedCustomFields = response.data.customFields.map((field:any) => ({
        name: field.name,
        type: field.type === "text" ? "character varying" : field.type,
      }));
      
      const combinedNames = [
        ...predefinedFields.map((field: any) => field.name),
        ...extractedCustomFields.map((field: any) => field.name),
      ];
  
      setExistingFields(combinedNames);
    } else {
      setExistingFields([]);
    }
  }

  useEffect(() => {
    if(successRes === true) {
      dispatch(getListsRequest(queryParams));
      toast(listRes.message)
      handleClose();
      formik.resetForm(); 
    }
    errorRes && toaster({success: false, message: errorRes.message});
    // dispatch(clearListData());
  }, [successRes ,errorRes])

  const sortedList = [...(list?.contactList || [])].sort((a, b) => {
    if (sortColumn === 'List Name') {
        const aValue = a.name.toLowerCase();
        const bValue = b.name.toLowerCase();
        return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else if(sortColumn === 'Contact Count') {
        const aValue = a['contact_count'];
        const bValue = b['contact_count'];
        return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else {
        const aValue = new Date(a.updated_at);
        const bValue = new Date(b.updated_at);
        return sortOrder === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
    }
  });

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleSortClick = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
  };

  const handleDeleteList =(listId : string)=>{
    dispatch(deleteListsRequest({listId}));
    setShowDeleteModal(false)
  }

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const getAllContacts = async (value?: string) => {
    try {
      if (!value) {
        return []
      }
      const payload = {
        limit: 10,
        searchEmailString: value,
        excludeIds: []
      }
      payload.excludeIds = selectedOptions.map((option: { value: string }) => +option.value);
      const res = await postData(API_ENDPOINTS.GET_ALL_CONTACTS , payload)
      const data = res.contactList
      const formattedOptions: Option[] = data.map((item: any) => ({
        value: item.id.toString(),
        label: item.email,
      }));
      setOptions(formattedOptions);
      return formattedOptions
    }
    catch (error: any) {
      return []
    }
  }

  const debouncedGetAllContacts = debounceAsync(getAllContacts, 600);

  const handleSelectChange = (selected: any, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'select-option' && selected && selected[0].value === 'all'){
      setSelectedOptions(options);
    }
    else setSelectedOptions(selected);
  };


  const handleEditList =(listId : string)=>{
    setListId(listId);
    const filterData =  filterDataById(listId)
    if(filterData[0].contact_count >0){
      const manipulatedOptions: Option[] = filterData[0].contacts?.map((originalOption: { value: string; label: string; }) => {
        return {
          value: (originalOption.value).toString(),
          label: originalOption.label,
        };
      });
      setSelectedOptions(manipulatedOptions);
    }
    formik.setFieldValue('listName', filterData[0].name)
    handleShow();
  }

  const filterDataById = (idToFilter: string) => {
    const filteredData = list?.contactList.filter((item: { id: string; }) => item.id === idToFilter);
    return filteredData;
  };
  const handleSelectAll = () => {
    setSelectedOptions((prevSelectedOptions:any) => [...prevSelectedOptions, ...options]);
    setOptions([]);
    // setSelectAllOpen(false);
  };

  const handleFileChange = (input: HTMLInputElement) => {
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      setCsvFields([]);
      setExistingFields([]);
      
      if(file.size <= 3145728) {
        const reader = new FileReader();

      reader.onload = async () => {
        await getPredefinedAndCustomFieldsName()
        const result = reader.result as string;
        const lines = result.split("\n");
        const headers = lines[0].split(",").map(header => header.trim());
        setCsvFields(headers);
        setCsvFile(file);
      };

      reader.readAsText(file);
    }
    } else {
      alert("File size exceeds the 3MB limit.");
    }
  };

  const handleFieldChange = (csvField: string, selectedDatabaseField: string, index: number) => {
    setPreselectedValue((prevValues: any) => {
      // Copy the existing array
      const updatedValues = [...prevValues];

      // Update the mapping for the specific index
      updatedValues[index] = { csvField, databaseField: selectedDatabaseField };

      return updatedValues;
    });
  };
 
  const handleDeleteIconClick = (list: any) => {
    setSelectedList(list);
    setShowDeleteModal(true);
  }

  const downloadCsvFormat = () => {
    downloadCsv(predefinedFieldList)
  };

  return (
    <div>
    <div className="main-title d-flex align-items-center justify-content-between">
      <h2 className='d-flex align-items-center'>
        <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Lists</h2>
        {(permission?.edit) && (
          <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            onClick={()=>{ 
              setSelectedOptions([])
              setListId('')
              formik.resetForm();
              handleShow()}}
          >Create List
          </Button>
        )}
        <Modal show={show}
          onHide={()=>{
            handleClose()
            setCsvFile(null)
          }}
          centered>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title> {formik.values.listName ? "Edit Contact List" :"Create Contact List"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Contact List Name</Form.Label>
                <Form.Control type="text" placeholder="Enter"
                  name="listName"
                  value={formik.values.listName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.listName && !!formik.errors.listName}
                  required
                />
                <Form.Control.Feedback type="invalid">{formik.errors.listName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                <div className='d-flex justify-content-between align-items-center'>
                  <Form.Label>Select From Current Contacts</Form.Label>
                  <Button onClick={handleSelectAll} className="csv-download-btn-style" disabled={options.length === 0}>
                  <FontAwesomeIcon 
                    icon={faCheckDouble}
                    className='me-2'
                  />
                  Select All Contacts
                  </Button>
                </div>
                <AsyncSelect
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  isMulti
                  defaultOptions
                  loadOptions={debouncedGetAllContacts}
                  placeholder="Search contacts here"
                />
              </Form.Group>          
            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
              <div className='d-flex justify-content-between align-items-center'>
                <Form.Label>Import Contacts </Form.Label>
                <Button className="csv-download-btn-style" onClick={downloadCsvFormat}>
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    onClick={downloadCsvFormat}
                    className='me-2'
                  />
                  Download CSV Format 
                </Button>
              </div>
              <Form.Control type="file" onChange={(e) => handleFileChange(e.target as HTMLInputElement)} accept=".csv"  />
            </Form.Group>
            {csvFile && (
            <div className="d-flex justify-content-betweeen mt-3">
              <div className="w-100 ps-2">
                {csvFile && (
                  <>
                    <div className='d-flex'>
                      <h6 className='w-50'>CSV File Fields</h6>
                      <h6 className='w-50'>Existing Fields</h6>
                    </div>
                    <div className="d-flex flex-column">
                      {csvFields.map((csvField: any, index: any) => (
                        <div
                          key={index}
                          className="d-flex mb-3 justify-content-between align-items-center"
                        >
                          {/* CSV Field label */}
                          <div className="csv-mapping-field">
                            <span>{csvField}</span>
                          </div>

                          <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                          </div>

                          {/* Existing Fields dropdown */}
                          <div className="w-50">
                            <Form.Select
                              onChange={(e) =>
                              {const selectedValue = e.target.value;
                                if (selectedValue === "custom-field") {
                                  setShowCustomFieldsModal(true);
                                } else {
                                  handleFieldChange(csvField, selectedValue, index);
                                }}
                              }
                              className="form-select"
                              value={preselectedValue[index]?.databaseField || ""}
                            >
                              <option value="">Select a field</option>
                              {existingFields
                                .filter(
                                  (option: any) =>
                                    !Object.values(preselectedValue)
                                      .map((mapped: any) => mapped.databaseField)
                                      .includes(option) ||
                                    preselectedValue[index]?.databaseField ===
                                      option
                                )
                                .map((option: any, optionIndex: any) => (
                                  <option key={optionIndex} value={option}>
                                    {option}
                                  </option>
                                ))}
                                <option value="custom-field" onClick={()=>{setShowCustomFieldsModal(true)}}>Add Custom Field</option>
                            </Form.Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            )}
          </Modal.Body>
          <Modal.Footer className='justify-content-between'>
            <Button 
              variant="primary" 
              className='btn-theme btn-no-border' 
              onClick={()=>{
                handleClose()
                setCsvFile(null)
              }} >
              Close
            </Button>
            <XButton
              variant="primary"
              type="submit"
              buttonText={<>Save & Continue<FontAwesomeIcon icon={faPlay} /></>}
              loading={loading}
            /> 
          </Modal.Footer>
          </Form>
        </Modal>

        {/* Custom Fields Modal */}
      <Modal
        show={showCustomFieldsModal}
        onHide={() => {
          customFieldsFormik.resetForm();
          setShowCustomFieldsModal(false)}
        }
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={customFieldsFormik.handleSubmit}>
            <Row>
              <Col xl={6} md={6} xs={6}>
                <Form.Group>
                  <Form.Control
                    name="customFields[0].columnName"
                    type="text"
                    placeholder="Custom Name"
                    className="form-control mt-2"
                    value={customFieldsFormik.values.customFields[0].columnName}
                    onChange={customFieldsFormik.handleChange}
                    isInvalid={customFieldsFormik.touched.customFields && customFieldsFormik.touched.customFields[0] && !!((customFieldsFormik.errors.customFields as any)?.[0]?.columnName)}
                  />
                  <Form.Control.Feedback type="invalid">{((customFieldsFormik.errors.customFields as any)?.[0]?.columnName)}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} md={6} xs={6}>
                <Form.Group>
                  <Form.Select
                    name="customFields[0].columnType"
                    className="form-control mt-2"
                    value={customFieldsFormik.values.customFields[0]?.columnType}
                    onChange={customFieldsFormik.handleChange}
                    onBlur={customFieldsFormik.handleBlur}
                    isInvalid={customFieldsFormik.touched.customFields && customFieldsFormik.touched.customFields[0] && !!((customFieldsFormik.errors.customFields as any)?.[0]?.columnType)}
                  >
                    <option value="">Column Type</option>
                    {columnTypes.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{((customFieldsFormik.errors.customFields as any)?.[0]?.columnType)}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <br/>
              <div className='d-flex justify-content-between align-items-center'>
                <XButton
                  variant="primary"
                  type="submit"
                  buttonText="Submit"
                />
              </div>
            <br/>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
    {/* <div className="info-cards">
      <Row>
        <Col xl={4}>
          <div className="card">
            <h2 className='title'>Total Contacts</h2>
            <div className="text text-center">
              <span className='count'>0</span>
              <Button
                variant="primary"
                type="submit"
                className="btn-theme"
              >Export Contacts
              </Button>
            </div>
          </div>
        </Col>
        <Col xl={4}>
          <div className="card">
            <h2 className='title'>Emails Sent</h2>
            
          </div>
        </Col>
        <Col xl={4}>
          <div className="card">
            <h2 className='title'>Unsubscribes</h2>
            <div className="d-flex justify-content-between">
              <div className="text text-center">
                <span className='count'>0</span>
                <p className='text'>List Unsubscribes</p>
                <p className='sub-text'>0% Unsubscribe Rate</p>
              </div>
              <div className="text text-center">
                <span className='count'>0</span>
                <p className='text'>Global Unsubscribes</p>
                <p className='sub-text'>0% Unsubscribe Rate</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div> */}
    <div className="table-listing contact-list position-relative">
      <Table responsive>
        <thead>
          <tr>
          {tableHeadings_List.map((heading, index) => (
            <th key={index} 
              className={`${heading === "Actions" ? "" : "cursor-pointer"}`}
              onClick={() => handleSortClick(heading)}>
              <span style={{ marginRight: '5px' }}>{heading}</span>
              {heading !== 'Actions' && (
                <FontAwesomeIcon icon={sortColumn === heading && sortOrder === 'desc' ? faSortUp : (sortColumn === heading && sortOrder === 'asc' ? faSortDown : faSort)} />
              )}
            </th>
          ))}
          </tr>
        </thead>
        
        <tbody>
        {!loading && sortedList?.length === 0 &&
          <tr>
            <td colSpan={9}>
              <p className='text-center no-records'>No list found.</p>
            </td>
          </tr>
        }
        {!loading && sortedList && sortedList?.map((list: any) => (
              <tr key={list.id}>
                <td>
                  <span className='d-flex'>
                    <span className="d-flex align-items-center text-darkBlue">
                    {list.name}
                    </span>
                  </span>
                </td>
                <td>{list.contact_count}</td>
                <td>{new Date(list.created_at).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                <td className='action-badges'>
              {/* <button  onClick={() => handleDeleteList(list.id)}>
                <span className='d-flex align-items-center'>
                  Delete <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              </button> */}
              {(permission?.delete) && (
                <button onClick={() => handleDeleteIconClick(list)}>
                  <span className='d-flex align-items-center'>
                  <span>Delete <FontAwesomeIcon icon={faCircleXmark} /></span>
                    
                  </span>
                </button>
              )}
              {(permission?.edit) && (
                <button className='edit' onClick={() => handleEditList(list.id)}>
                  <span className='d-flex align-items-center' >
                    Edit <FontAwesomeIcon icon={faPenToSquare} />
                  </span>
                </button>
              )}
            </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {loading && (<XLoader />)}
    </div>
    <XDeleteModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    title="Delete List"
                    body={`Are you sure you want to delete the List?`}
                    onConfirm={() => {
                      if (selectedList) {
                        handleDeleteList(selectedList.id);
                      }
                      setShowDeleteModal(false);
                    }}
                    onCancel={() => setShowDeleteModal(false)}
                  />
      {!loading && sortedList?.length !== 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
      
  </div>
  )
}

export default Lists;