/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState } from "react";
import VerifyDomain from "./verifyDomain";
import useAxios from "../../../hooks/useAxios";
import { API_ENDPOINTS } from "../../../constants/api";
import { toaster } from "../../../utils/toast";
import useSidebar from "../../../hooks/useSidebar";
import XLoader from "../../../component/loader/XLoader";

const Domains: FC = () => {
  const [verifyModal, setVerifyModal] = useState(false);
  const [domains, setDomains] =useState<any[]>([]);
  const [dnsDetailData, setDnsDetailData] = useState();
  const { isLoading, postData} = useAxios();
  const { onShowSidebar } = useSidebar();

  useEffect(() => {
    getDomainList();
  }, [verifyModal]);

  const verifyDomainHandleClose = () => setVerifyModal(false);
  const verifyDomainHandleShow = () => setVerifyModal(true);
  const getDomainList = async () => {
    try {
      const response = await postData(API_ENDPOINTS.GET_ALL_DOMAINS);
      setDomains(response?.data ?? []);
    } catch (error) {
      toaster({ success: false, message: "error fetching domains" });
    }
  }
  const checkVerified = async (email:string) => {
    try {
      let payLoad={email}
      const response = await postData(API_ENDPOINTS.DOMAIN_DETAIL,payLoad);
      const { data } = response;
      if(data.is_verified === 0){
        setDnsDetailData(data);
        verifyDomainHandleShow()
      }
      else{
        domains && setDomains(prev => prev.map(domain => 
          domain.email === email ? { ...domain, status: true } : domain
      ));
      }

    } catch (error) {
      toaster({ success: false, message: "error fetching domains" });
    }
  }

  return (
    <div>
      <div className="main-title">
        <h2>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Domains
        </h2>
      </div>
      <div className="contact-details setting-domains">
        <div className="info-box">
          <h2>Email Domains</h2>
          <p>
            Your email domains control how your emails are sent through
            PatientCampaign. Verifying and authenticating your domain helps your
            hard work get to your customers' inboxes.
          </p>
          <Button
            variant="primary"
            type="submit"
            className="btn-theme d-flex align-items-center"
            onClick={() => {
              setDnsDetailData(undefined)
              verifyDomainHandleShow()
            }
            }
          >
            Add & Verify Domain
          </Button>
          {verifyModal && (
            <VerifyDomain
              show={verifyModal}
              handleClose={verifyDomainHandleClose}
              dnsDetail ={dnsDetailData}
            />
          )}
          <div className="table-listing all-contacts users-list position-relative px-0">
            { (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                  
                <tbody>
                  {!isLoading && domains.map((v) => {
                    const { email, status } = v;
                    return (
                      <tr key={v.id}>
                        <td><span className="underline" onClick={()=>{!status && checkVerified(email)}}>{email}</span></td>
                        <td><span className={`status-badge ${status ? "green" : "#7BAFD4"}`}>{status ? "Verified" : "Unverified"}</span></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {isLoading && <XLoader />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Domains;


