/* eslint-disable react-hooks/exhaustive-deps */
import { faBars, faCommentDots, faEllipsisVertical, faEnvelope, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Nav, NavDropdown, Row, Table, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { XModal } from '../../component';
import XButton from '../../component/button/XButton';
import { tableHeadings_ViewBy } from '../../constants/constants';
import { Campaign, PermissionObject } from '../../redux/modules/auth/types';
import { getCampaignsRequest, setCampaignEditData, setMessage, setStep, setcampaignId } from '../../redux/modules/compaigns/compaignSlice';
import { RootState } from '../../redux/rootReducer';
import { CampaignStatus, CampaignType } from '../../utils/enums';
import { debounce } from '../../utils/helper';
import CreateCampaign from './createCampaign';
import useSidebar from '../../hooks/useSidebar';
import { IMAGES } from '../../constants/imagePath';
import PaginationNav from '../../component/pagination/pagination';
import XLoader from '../../component/loader/XLoader';
import SingleCampaignAnalytics from './SingleCampaignAnalytics';

interface CompaignProps {
    permission?: PermissionObject
  }

const Compaign: React.FC<CompaignProps> = (props) => {
    const { permission } = props
    const dispatch = useDispatch();
    const { campaigns, totalCount} = useSelector((state: RootState) => state.campaigns.campaignlist);
    const { step, message, loading } = useSelector((state: RootState) => state.campaigns);
    const [selectedType, setSelectedType] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectedSortOption, setSelectedSortOption] = useState<string>('');
    const [queryParams, setQueryParams] = useState({
        status: '',
        type: '',
        search: '',
        page: 1,
        limit: 10,
        sortBy:'LastUpdated'
    });
    const [previewHtml, setPreviewHtml] = useState<string>("");
    const { onShowSidebar } = useSidebar();
    const [activeId, setActiveId] = useState<any>('first');
    const { smsFeature } = useSelector((state: RootState) => state.user);
    const [selectedCampaignId, setSelectedCampaignId] = useState<any>(null)

    useEffect(() => {
        if (message && (step === 0)) {
          toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, // Close the notification after 2 seconds
          });
          dispatch(setMessage(''))
        }
      }, [message, step]);

    const actionHandler = (value: string, campaign: Campaign) => {
        if (value === 'edit') {
            dispatch(setCampaignEditData(campaign))
            dispatch(setcampaignId(campaign.id))
            handleShow()
        }
        if (value === 'replicate') {
            dispatch(setCampaignEditData(campaign))
            handleShow()
        }
        if (value === 'viewEmail') {
            setPreviewHtml(campaign.content)
        }
    }

    const handleTabChange = (tabKey: string): void => {
        if (tabKey === 'first') {
            setQueryParams((prevParams) => ({ ...prevParams, status: '', type: type, page: 1 }));
        } else if (tabKey === 'second') {
            setQueryParams((prevParams) => ({ ...prevParams, status: CampaignStatus.SCHEDULED, type: type, page: 1 }));
        } else if (tabKey === 'third') {
            setQueryParams((prevParams) => ({ ...prevParams, status: CampaignStatus.DRAFT, type: type, page: 1 }));
        } else if (tabKey === 'fourth') {
            setQueryParams((prevParams) => ({ ...prevParams, status: CampaignStatus.COMPLETED, type: type, page: 1 }));
        } else if (tabKey === 'fifth') {
            setQueryParams((prevParams) => ({ ...prevParams, type: CampaignType.EMAIL, status: '' }));
        } else if (tabKey === 'sixth') {
            setQueryParams((prevParams) => ({ ...prevParams, type: CampaignType.SMS, status: '' }));
        }

    };
    const handleFilterChange = (filterType: string, value: string): void => {
        if (filterType === 'type') {
            setQueryParams((prevParams) => ({ ...prevParams, [filterType]:value }));
        } else if (filterType === 'sortBy') { 
            setQueryParams((prevParams) => ({ ...prevParams, sortBy: value }));
        }
    };
    const handlePageChange = (pageNumber: number) => {
        setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
    };

    const handleSearchInputChange = (searchValue: string): void => {
        setQueryParams((prevParams) => ({ ...prevParams, search: searchValue, page: 1 }));
    };

    const debouncedHandleSearchChange = debounce(handleSearchInputChange, 500);


    const handleShow = () => {
        dispatch(setStep(1))
    };
   
    useEffect(() => {
        if (step === 0) {
            dispatch(getCampaignsRequest(queryParams));
            dispatch(setCampaignEditData(null))
            dispatch(setcampaignId(''))
        }
    }, [queryParams, step]);

    useEffect(() => {
        const totalCampaigns = totalCount;
        const totalPages = Math.ceil(totalCampaigns / queryParams.limit);
        setTotalPages(totalPages);
    }, [totalCount, queryParams.limit]);

    useEffect(()=>{
        return ()=>{
            dispatch(setCampaignEditData(null))
            dispatch(setcampaignId(''))
            dispatch(setStep(0))
        }
    }, [])


    const formatDate = (timestamp: any) => {
        return moment(timestamp).format('dddd, MMMM Do YYYY h:mm A');
    };

    const onCampaignNameClick = async (campaignId: any) => {
        setSelectedCampaignId(campaignId)
    };

    return (
        <div>
            {previewHtml && (
                <XModal
                    title="Preview"
                    show={!!previewHtml}
                    handleClose={() => setPreviewHtml("")}
                    size="xl"
                    body={
                        <div style={{ maxHeight: "67vh", overflowY: "auto" }}>
                            <div
                                style={{ pointerEvents: 'none' }}
                                dangerouslySetInnerHTML={{
                                    __html: previewHtml ?? "",
                                }}
                            />
                        </div>    
                    }
                />
            )}
            {/* --------- campaign starts --------------------- */}
           { step <= 1 && <> <div className="main-title d-flex align-items-center justify-content-between">
                <h2 className='d-flex align-items-center'>
                    <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Campaign</h2>
                {(permission?.edit) && (<XButton
                    variant="primary"
                    buttonText='Create New Campaign'
                    className="btn-theme"
                    onClick={handleShow}
                />)}
                
            </div>
            <div className="tabs">
                <div id="left-tabs-example">
                    <Row>
                        <Col xxl={2} xl={12}>
                            <div className="tabs-wrap">
                            {tableHeadings_ViewBy.map((view:any, index:number) => (
                                <div className="tabs-inner" key={index}>
                                <p className='tabs-title'>{view.viewType}</p>
                                    <Nav variant="pills" className="flex-column">
                                        {view.child.map((heading:any, index:number) => (
                                            <Nav.Item key={index}>
                                                <Nav.Link eventKey={heading.id}
                                                    className={heading.id === activeId ? 'active' : ''}
                                                    onClick={() => {
                                                        setType(heading?.id === 'fifth' ? CampaignType.EMAIL : heading?.id === 'sixth' ? CampaignType.SMS : type)
                                                        handleTabChange(heading.id);
                                                        setActiveId(heading.id);
                                                      }
                                                    }
                                                > <Image src={IMAGES[heading.icon as keyof typeof IMAGES]} /> {heading.name}</Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                            </div>
                            ))}
                            </div>
                        </Col>
                        <Col xxl={10} xl={12}>
                            <div className="filter-bar d-flex align-items-center justify-content-between w-100">
                                <div className='position-relative search-bar'>
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        // value={queryParams.search}
                                        onChange={(e) => debouncedHandleSearchChange(e.target.value)}
                                    />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                                <div className="select-box d-flex align-items-center w-100 justify-content-end">
                                    <Form.Group className="d-flex align-items-center filter-box" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Filter By Type</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            onChange={(e) => {
                                                const selectedOption = e.target.options[e.target.selectedIndex];
                                                const selectedValue = selectedOption.value;
                                                const selectedText = selectedOption.text 
                                                const selectText=selectedText === 'Select' ? '' : selectedText;
                                                setSelectedType(selectedValue);
                                                setType(selectedText === 'Select' ? '' : selectedText)
                                                handleFilterChange('type', selectText);
                                            }
                                            }
                                            value={selectedType}
                                        >
                                            <option>Select</option>
                                            <option value="1">Email</option>
                                            {smsFeature && <option value="2">SMS</option>}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Sort By</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                          onChange={(e) => {
                                            const selectedOption = e.target.options[e.target.selectedIndex];
                                            const selectedValue = selectedOption.value;
                                            const selectedText = selectedOption.text;
                                            setSelectedSortOption(selectedValue); 
                                            handleFilterChange('sortBy', selectedText);
                                        }}
                                        value={selectedSortOption} 
                                        >
                                            <option value="">Select</option>
                                            <option value="1">LastUpdated</option>
                                            <option value="2">CreationDate</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                                    <div className="table-listing position-relative">
                                        {loading && <XLoader />}
                                        <Table responsive>
                                            <tbody>
                                                {!loading && campaigns && campaigns.length > 0 &&
                                                    campaigns
                                                        .map((campaign: Campaign) => {
                                                            const { scheduled_time: scheduledTime, type, status } = campaign

                                                            return (
                                                                <tr key={campaign.id}>
    
                                                                    <td className='d-flex'>
                                                                        <span className="text text-start cursor-pointer" onClick={()=>onCampaignNameClick(campaign.id)}>
                                                                            <h4 className='email'>{campaign.title}</h4>
                                                                            <p className='date'>Scheduled at { scheduledTime ? 
                                                                                <span className='text-darkBlue'>{formatDate(scheduledTime)} 
                                                                                    <span className='date'> by you</span>
                                                                                </span> : "" }
                                                                            </p>
                                                                        </span>
                                                                    </td>
                                                                    <td> <span className="text text-start ">
                                                                        <h4 className='number'>
                                                                            {campaign.contact_count > 0 && !isNaN(campaign.open) && type === CampaignType.EMAIL ?
                                                                                `${((campaign.open / campaign.contact_count) * 100).toFixed(2)}%` : 
                                                                                "N/A"}
                                                                        </h4>
                                                                        <p className='status'>Open</p>
                                                                    </span></td>
                                                                    <td> <span className="text text-start ">
                                                                        <h4 className='number'>{campaign.contact_count > 0 && !isNaN(campaign.click) && type === CampaignType.EMAIL ?
                                                                            `${((campaign.click / campaign.contact_count) * 100).toFixed(2)}%` :
                                                                            "N/A"}</h4>
                                                                        <p className='status'>Clicks</p>
                                                                    </span></td>
                                                                    <td>
                                                                        {type === CampaignType.EMAIL ? <span className='border-badge'>
    
                                                                            <FontAwesomeIcon icon={faEnvelope} /> {type}
                                                                        </span>
                                                                            :
                                                                            <span className='border-badge sms'>
                                                                                <FontAwesomeIcon icon={faCommentDots} /> {type.toUpperCase()}
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <span className={`status-badge ${status === 'Scheduled' ? 'blue' : (status === 'Completed' ? 'green' : 'status-badge')}`}>
                                                                             {status}
              </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="profile d-flex hide-dropdown-arrow">
                                                                            <NavDropdown
                                                                                title={<FontAwesomeIcon icon={faEllipsisVertical} />}
                                                                            >
                                                                                {(permission?.edit && status === CampaignStatus.DRAFT) && <><NavDropdown.Item onClick={() => actionHandler('edit', campaign)}>
                                                                                    Edit
                                                                                </NavDropdown.Item><NavDropdown.Divider /></>}
    
                                                                                {campaign.content && <><NavDropdown.Item onClick={() => actionHandler('viewEmail', campaign)}>
                                                                                {type === CampaignType.EMAIL ? 'View Email' : "View SMS"}
                                                                                </NavDropdown.Item>
                                                                                    <NavDropdown.Divider />
                                                                                </>
                                                                                }
    
                                                                                <NavDropdown.Item onClick={() => actionHandler('replicate', campaign)}>
                                                                                    Replicate
                                                                                </NavDropdown.Item>
                                                                            </NavDropdown>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                {!loading && campaigns?.length === 0 &&
                                                    (
                                                        <tr style={{border: "transparent"}}>
                                                            <td> 
                                                                <div className="campaign-box">
                                                                    <h3 className='title'>Reach your audience in minutes</h3>
                                                                    <p className='sub-title'>Once you create an email campaign, it will show up here.</p>

                                                                    {(permission?.edit) && (<XButton
                                                                        variant="primary"
                                                                        buttonText='Create New Campaign'
                                                                        className="btn-theme"
                                                                        onClick={handleShow}
                                                                    />)}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <SingleCampaignAnalytics
                                            selectedCampaignId={selectedCampaignId}
                                            setSelectedCampaignId={setSelectedCampaignId}
                                        />
                                    </div>
                        </Col>
                    </Row>
                </div>

                {/* ---------------Pagination added----------- */}
                {!loading && campaigns?.length > 0 && (
                  <PaginationNav
                    totalPages={totalPages}
                    currentPage={queryParams.page}
                    handlePageChange={handlePageChange}
                  />
                )}
                {/* ---------------Pagination Ended----------- */}


            </div> 
            </>}

            {step > 0 && <CreateCampaign/>}
            {/* --------- campaign ends --------------------- */}
        </div>
    )
}

export default Compaign;