import { useFormik } from 'formik';
import { FC, ReactNode, useContext, useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import Select from 'react-select';
import * as Yup from "yup";
import { XModal } from '../../component';
import { IMAGES } from '../../constants/imagePath';
import { ListMapType } from '../../hooks/useList';
import { SegmentMapType } from '../../hooks/useSegment';
import { TriggerMapType } from '../../hooks/useTrigger';
import { TriggerDataType } from './useJourneyBuilder';
import { EXECUTION_TYPE, TRIGGER } from '../../utils/enums';
import { JourneyContext } from './JourneyContext';

interface Props {
    show: boolean;
    handleClose: () => void;
    triggerMap: TriggerMapType,
    listMap: ListMapType,
    segmentMap: SegmentMapType,
    addTriggerDataHandler: (data: TriggerDataType) => void
}

const TriggerModal: FC<Props> = ({ show, handleClose, triggerMap, listMap, segmentMap, addTriggerDataHandler }) => {
    const { triggerData} = useContext(JourneyContext)
    const [triggerId, setTriggerId] = useState<number>(triggerData?.triggerId ?? 0);
    const validationSchema = Yup.object().shape({
        toListId: Yup.array().min(1, 'Select at least one option'),
        toListType: Yup.string().required("list type is required"),
        ...((triggerId === TRIGGER.SEPECIFIC_DATE) && {
            dateField: Yup.string().required("date field is required"),
            dayBefore: Yup
                .number()
                .required('Day before is required')
                .min(0, 'Day before must be greater than or equal to 0')
                .max(90, 'Day before must be less than or equal to 90')
                .integer('Day before must be an integer'),
        }),
        ...(((triggerId === TRIGGER.LIST) || (triggerId === TRIGGER.SEGMENT)) && {
            executionType: Yup.string().required("execution type is required"),
        }),
    });
    const formik = useFormik({
        initialValues: {
            toListId: triggerData?.toListId ?? [],
            dateField: triggerData?.dateField ?? '',
            dayBefore: isNaN(triggerData?.dayBefore) ? undefined : +triggerData?.dayBefore,
            executionType: triggerData?.executionType ?? EXECUTION_TYPE.CURRENT,
            toListType: triggerData?.toListType ?? 1,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { toListId, toListType, dateField, executionType, dayBefore } = values
            addTriggerDataHandler({
                triggerId,
                toListType: +toListType,
                toListId,
                dateField,
                executionType,
                dayBefore,
            })
            handleClose()
        },
    });

    const triggerClickHandler = (val: number) => {
        if ((val === 1) || (val === 2)) {
            formik.setFieldValue('toListType', val)
            formik.setFieldValue('toListId', [])
        }
        setTriggerId(val)
    }

    const triggerSubModal = !!triggerId

    const modalBody = (
        <div className='trigger-cards'>
            <Row>
                {Object.values(triggerMap).map((v, k) => (<Col key={v.id} xxl={4} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="card" onClick={() => triggerClickHandler(v.id)}>
                        <div className="d-flex align-items-center">
                            <Image src={IMAGES.calenderIcon} />
                            <span className="text-title">{v.name}</span>
                        </div>
                        <ul>
                            <li>{v.description}</li>
                        </ul>
                    </div>
                </Col>)
                )}
            </Row>
        </div>
    );

    return (
        <>
            <XModal
                size="lg"
                title="Add Trigger Point"
                show={show}
                body={modalBody}
                handleClose={handleClose}
                className='lg'
            />
            {
                triggerSubModal && <XModal show={triggerSubModal}
                    backdrop='static'
                    handleClose={() => setTriggerId(0)}
                    title={triggerMap[triggerId].name}
                    confirmText='Add Trigger'
                    handleConfirm={formik.handleSubmit}
                    body={<Form>
                        {triggerId === TRIGGER.SEPECIFIC_DATE && (<>
                            <Form.Group className="form-group">
                                <Form.Label>Days Before Date (Max: 90 days)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter"
                                    {...formik.getFieldProps('dayBefore')}
                                    isInvalid={formik.touched.dayBefore && !!formik.errors.dayBefore}
                                />
                                {formik.errors.dayBefore && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.dayBefore}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group
                                className="form-group">
                                <Form.Label>Date Field</Form.Label>
                                <Form.Select
                                    name="dateField"
                                    value={formik.values.dateField}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.dateField && !!formik.errors.dateField}
                                >
                                    <option value="">Select</option>
                                    <option value='DOB'>Date of Birth</option>
                                    <option value='created_at'>Anniversary</option>

                                </Form.Select>
                                {formik.errors.dateField && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.dateField as ReactNode}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </>)}
                        {triggerId === TRIGGER.SEPECIFIC_DATE && (<div className="content d-flex align-items-center justify-content-between mb-3">
                            <Form.Label className='mb-0'>Select to initiate the journey</Form.Label>
                            <Form.Group controlId="formBasicRadiobox" className='d-flex align-items-center custom-radio-btns gap-3'>
                                <Form.Check
                                    type="radio"
                                    label="Segment"
                                    className="d-flex align-items-center"
                                    checked={+formik.values.toListType === 2}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        formik.setFieldValue("toListId", []);
                                    }}
                                    name="toListType"
                                    value="2"
                                    id="segment_radio"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Contact List"
                                    className="d-flex align-items-center"
                                    checked={+formik.values.toListType === 1}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        formik.setFieldValue("toListId", []);
                                    }}
                                    name="toListType"
                                    value="1"
                                    id="contact_radio"
                                />
                            </Form.Group>
                        </div>)}
                        {(+formik.values.toListType === 1) && (
                            <Form.Group className="form-group">
                                <Select
                                    options={Object.values(listMap)}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={(selected) => {
                                        formik.setFieldValue('toListId', selected);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={[...formik.values.toListId]}
                                />
                            </Form.Group>
                        )}

                        {+formik.values.toListType === 2 && (
                            <Form.Group className="form-group">
                                <Select
                                    options={Object.values(segmentMap)}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={(selected) => {
                                        formik.setFieldValue('toListId', selected);
                                    }}
                                    value={[...formik.values.toListId]}
                                />
                            </Form.Group>
                        )}
                        {formik.errors.toListId && (
                            <Form.Text className='text-danger error-text'>{formik.errors.toListId && formik.touched.toListId && <div className="text-danger error-text">{formik.errors.toListId as ReactNode}</div>}</Form.Text>
                        )}

                        {(triggerId === TRIGGER.LIST || triggerId === TRIGGER.SEGMENT) && (<Form.Group controlId="formBasicRadiobox" className='d-flex align-items-center custom-radio-btns gap-3'>
                            <Form.Check
                                type="radio"
                                label="Send to current"
                                className="d-flex align-items-center"
                                checked={formik.values.executionType === EXECUTION_TYPE.CURRENT}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                name="executionType"
                                value={EXECUTION_TYPE.CURRENT}
                                id="send_to_current_radio"
                            />
                            <Form.Check
                                type="radio"
                                label="Send to current and newly added"
                                className="d-flex align-items-center"
                                checked={formik.values.executionType === EXECUTION_TYPE.CURRENT_NEW_BOTH}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                name="executionType"
                                value={EXECUTION_TYPE.CURRENT_NEW_BOTH}
                                id="send_current_and_newly_added_radio"
                            />
                        </Form.Group>)}

                    </Form>} />
            }
        </>
    );
};

export default TriggerModal;
